import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { OnBoardingComponent } from './onboarding/onboarding.component';
import { RgpdComponent } from './rgpd/rgpd.component';

const routes: Routes = [
  {
  path: 'onboarding',
  component: OnBoardingComponent
},{
  path: 'vie-privee',
  component: RgpdComponent
}
,{
  path:'**',
  redirectTo: 'onboarding'
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
