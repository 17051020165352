import { Component } from '@angular/core';

@Component({
  selector: 'partner-capital-rgpd',
  templateUrl: './rgpd.component.html',
  styleUrls: ['./rgpd.component.scss'],
})

export class RgpdComponent  {

  constructor() { }
}
