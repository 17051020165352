<section id="section-1">
  <div class="container">
    <div class="wrapper">
      <img
        class="logo"
        src="https://www.effy.fr/common-assets/images/logo_effy.svg"
      />
      <h1>Politique de protection des données</h1>
      <hr class="separator" />
      <p>
        La présente politique de protection des données vise à vous informer de
        nos pratiques concernant la collecte, l’utilisation, le traitement et le
        partage des données personnelles que vous êtes amenés à nous fournir
        lors de votre navigation sur notre site internet&nbsp;:
        <a href="https://www.effy.fr/" target="_blank">www.effy.fr</a> (ci-après
        le&nbsp;«&nbsp;<b>Site</b>&nbsp;») ainsi que dans le cadre des offres
        que nous proposons à partir du Site et ce, conformément aux dispositions
        légales, en particulier la loi n°78-17 du 6 janvier 1978 modifiée, le
        règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril
        2016 relatif à la protection des personnes physiques à l’égard du
        traitement des données à caractère personnel et à la libre circulation
        de ces données («&nbsp;<b>RGPD</b>&nbsp;»).
      </p>
      <p>
        La présente politique de protection des données a pour objet de vous
        préciser&nbsp;:
      </p>

      <ul>
        <li>
          La façon dont vos données à caractère personnel sont collectées et
          traitées ;
        </li>
        <li>Vos droits concernant ces données ;</li>
        <li>Les éventuels destinataires de vos données ;</li>
        <li>
          La politique du Site en matière de cookies que nous déposons sur votre
          terminal de navigation.
        </li>
      </ul>
      <p>&nbsp;</p>

      <h3 class="text__subtitle">1. Vos données collectées</h3>

      <p>
        Nous collectons et traitons certaines de vos données personnelles et
        informations par le biais du Site mais aussi par téléphone et par
        courrier.&nbsp;
      </p>

      <p>
        La communication des informations identifiées par un astérisque (*) sur
        le Site est obligatoire et conditionne le traitement de votre demande.
        Les réponses aux autres questions sont facultatives.
      </p>

      <h3 class="text__subtitle">
        <span style="font-size: 18px"
          >1.1.&nbsp;Les données et informations que vous nous transmettez
          directement :&nbsp;</span
        >
      </h3>

      <p>1.1.1. Lors de votre inscription sur le Site</p>

      <ul>
        <li>
          Les données vous concernant et de nature à vous identifier&nbsp;:
          votre nom, email, numéro de téléphone, adresse.
        </li>
        <li>
          Les données relatives à votre habitat&nbsp;: caractéristiques du
          logement (type et taille de la surface à isoler, énergie de chauffage
          à isoler, mode de chauffage du logement…), statut de propriété
          (propriétaire ou locataire), données sur la composition du ménage
          (nombre de personnes composant le foyer) etc…
        </li>
        <li>Vos données de connexion&nbsp;: identifiant et mot de passe.</li>
      </ul>
      <p>
        1.1.2. Les informations&nbsp;recueillies lors de l’envoi d’un formulaire
        en ligne de demande d’informations concernant l’un de nos produits ou
        services.
      </p>

      <p>
        1.1.3. Les informations que vous nous transmettez lorsque vous contactez
        notre service-client&nbsp;: la nature de la réclamation et toutes
        informations nécessaires au traitement de votre demande.
      </p>

      <p>
        1.1.4. Les informations que vous renseignez lorsque vous laissez un avis
        sur le Site
      </p>

      <p>
        1.1.5. Les informations que vous nous transmettez lors de participation
        à des opérations promotionnelles, jeux concours ou événements que nous
        organisons.
      </p>

      <p>
        1.1.6. Les informations contenues dans l'attestation sur l'honneur que
        vous nous transmettez à la suite de la réalisation de travaux de
        rénovation énergétique
      </p>

      <h3 class="text__subtitle">
        <span style="font-size: 18px"
          >1.2.&nbsp;Les données à risque mais non sensibles au sens RGPD que
          vous nous transmettez&nbsp;:&nbsp;</span
        >
      </h3>

      <p>
        Dans le cadre des offres que nous proposons sur le Site, vous devez nous
        transmettre le montant de vos revenus ainsi qu’une copie de votre
        dernière déclaration fiscale. Ces données sont utilisées dans le cadre
        de la réglementation des certificats d’économie d’énergie
        («<b>&nbsp;CEE</b>&nbsp;») pour préciser votre niveau de précarité
        énergétique éventuel au sens des CEE.
      </p>

      <h3 class="text__subtitle">
        <span style="font-size: 18px"
          >1.3.&nbsp;Données que nous collectons automatiquement</span
        >
      </h3>

      <p>
        Afin d’assurer le suivi de nos services et aux fins de formation de nos
        conseillers, nous pouvons être amenés à enregistrer le contenu des
        échanges téléphoniques et par emails que vous avez avec notre service
        client.
      </p>

      <p>&nbsp;</p>

      <h3 class="text__subtitle">2. Finalités des traitements</h3>

      <p>
        Les données personnelles et informations collectées vous concernant sont
        destinées à&nbsp;:
      </p>

      <p>2.1. Vous accompagner dans le cadre :&nbsp;</p>

      <ul>
        <li>
          De l’obtention d’une aide ou subvention pour la réalisation de vos
          travaux de rénovation énergétique&nbsp;dans le cadre du dispositif des
          CEE&nbsp;;
        </li>
        <li>
          De la réalisation de vos travaux de rénovation énergétique&nbsp;dans
          le cadre du dispositif des CEE&nbsp;;
        </li>
        <li>
          De la constitution de vos dossiers de demandes de primes auxquelles
          vous seriez éligibles.
        </li>
      </ul>
      <p>
        Ces traitements sont nécessaires à l’exécution de nos obligations
        contractuelles.
      </p>

      <p>
        2.2.&nbsp;Vous fournir un espace personnel vous permettant de suivre vos
        demandes de devis et de prime : ce traitement est nécessaire à
        l’exécution de nos obligations contractuelles.
      </p>

      <p>
        2.3.&nbsp;Vous mettre en relation avec nos partenaires, professionnels
        spécialisés dans la réalisation de travaux de rénovation énergétique,
        dans le cas où vous en auriez manifesté la volonté : ce traitement
        nécessite que nous recueillions votre consentement.
      </p>

      <p>
        2.4.&nbsp;La gestion de vos demandes de primes auxquelles vous seriez
        éligibles : ce traitement est nécessaire à l’exécution de nos
        obligations contractuelles.
      </p>

      <p>
        2.5.&nbsp;Vérifier votre identité et vos données personnelles : ce
        traitement nécessite que nous recueillions votre consentement.
      </p>

      <p>
        2.6.&nbsp;Percevoir le cas échéant vos paiements : ce traitement est
        nécessaire à l’exécution de nos obligations contractuelles.
      </p>

      <p>
        2.7.&nbsp;Vous verser le cas échéant les primes auxquelles vous seriez
        éligibles : ce traitement est nécessaire à l’exécution de nos
        obligations contractuelles.
      </p>

      <p>
        2.8.&nbsp;Analyser votre navigation sur le Site et mener des enquêtes de
        satisfaction pour améliorer nos services : ce traitement est fondé sur
        notre intérêt légitime.
      </p>

      <p>
        2.9.&nbsp;Etablir des statistiques et comptages de fréquentation et
        d’utilisation des diverses rubriques et contenus de notre Site et de nos
        services : ce traitement est fondé sur notre intérêt légitime.
      </p>

      <p>
        2.10.&nbsp;A des fins commerciales et publicitaires, pour l’affichage de
        bannières publicitaires en lien avec vos centres d’intérêts : ce
        traitement nécessite que nous recueillions votre consentement.
      </p>

      <p>&nbsp;</p>

      <h3 class="text__subtitle">3. Responsable du traitement</h3>

      <p>
        Les données à caractère personnel vous concernant, collectés sur le
        Site, sont traités par Effy Connect, SAS au capital de 507 102&nbsp;€,
        immatriculée sous le numéro 504 201&nbsp;716 RCS PARIS, ayant son siège
        social 33 avenue du Maine 75755 Paris Cedex 15 – BP195.
      </p>

      <p>&nbsp;</p>

      <h3 class="text__subtitle">4. Transmission des données</h3>

      <p>
        Vos données sont destinées à Effy Connect. Cependant, nous pouvons être
        amené à transmettre certaines de vos données à certains destinataires
        qui présentent les garanties requises par la réglementation applicable
        en matière de protection des données à caractère personnel.
      </p>

      <h3 class="text__subtitle">
        <span style="font-size: 18px">4.1.&nbsp;Au sociétés du groupe</span>
      </h3>

      <p>
        Nous vous informons que des données à caractère personnel vous
        concernant peuvent être transmises à d’autres sociétés du Groupe Effy
        auquel Effy Connect appartient au sens des articles L. 233-1 et suivants
        du Code de Commerce, et ce à des fins de&nbsp;:
      </p>

      <ul>
        <li>
          Résolution et traitement des réclamations que vous pourriez
          avoir&nbsp;;
        </li>
        <li>Prospection commerciale, dans le cas où vous l’auriez accepté.</li>
      </ul>
      <h3 class="text__subtitle">
        <span style="font-size: 18px"
          >4.2.&nbsp;A nos prestataires de services</span
        >
      </h3>

      <p>
        Dans le cadre de nos activités, nous faisons appel à des sociétés
        tierces pour la réalisation de certaines prestations de services telles
        que la gestion des appels entrant et sortant, la gestion du back office,
        l’hébergement du Site et des données, l’envoi des emails de
        confirmation, le paiement/versement des primes, etc. Pour la réalisation
        de ces prestations, nous vous informons que nous sommes amenés à
        transmettre à ces prestataires de services certaines données
        personnelles et informations vous concernant. Ces prestataires n’ont
        accès qu’aux seules données nécessaires à l'exécution de leurs
        prestations et ne sont pas autorisés à traiter les données des
        utilisateurs pour d’autres usages.
      </p>

      <h3 class="text__subtitle">
        <span style="font-size: 18px"
          >4.2.&nbsp;Aux partenaires de notre réseau dans le cadre d’une mise en
          relation que vous auriez demandée</span
        >
      </h3>

      <p>
        L’obtention des primes que nous proposons suppose la réalisation de
        travaux de rénovation énergétique à votre domicile. Afin de pouvoir
        mener à bien ces travaux, nous pouvons, si vous en avez fait la demande,
        vous mettre en relation avec nos partenaires de notre réseau, qui sont
        des professionnels du bâtiment et à qui nous transmettons les seules
        données personnelles et informations vous concernant nécessaires à votre
        mise en relation et à la réalisation de vos travaux.
      </p>

      <h3 class="text__subtitle">
        <span style="font-size: 18px"
          >4.2.&nbsp;A nos partenaires/sous-traitants</span
        >
      </h3>

      <p>
        La réalisation de certaines de nos offres (travaux d’isolation pour 1€,
        installation de PAC…) que nous proposons suppose la réalisation de
        travaux de rénovation énergétique à votre domicile. Afin de pouvoir
        mener à bien ces travaux, nous avons recours à des
        partenaires/sous-traitants qui sont des professionnels du bâtiment et à
        qui nous transmettons les seules données personnelles et informations
        vous concernant nécessaires à votre mise en relation et à la réalisation
        de vos travaux.
      </p>

      <h3 class="text__subtitle">
        <span style="font-size: 18px"
          >4.3.&nbsp;Aux organismes administratifs et de contrôles qualité liés
          à la mise en œuvre du dispositif des CEE</span
        >
      </h3>

      <p>
        L’ensemble de nos offres est proposé dans le cadre de la réglementation
        liée aux CEE. Dès lors, nous transmettons aux organismes administratifs
        et de contrôle qualité qui gèrent la mise en œuvre de ce dispositif
        certaines données et informations vous concernant telles que
        l’attestation sur l’honneur que vous nous remettez, le montant de vos
        revenus, votre nom, adresse etc.
      </p>

      <h3 class="text__subtitle">
        <span style="font-size: 18px">4.4.&nbsp;Aux autorités</span>
      </h3>

      <p>
        Conformément à la règlementation et à nos obligations légales, nous
        pouvons être amenés à divulguer vos données à caractère
        personnel&nbsp;aux autorités administrative ou judiciaire lorsque leur
        divulgation est nécessaire à l'identification, l'interpellation ou la
        poursuite en justice de tout individu susceptible de porter préjudice à
        nos droits, de tout autre utilisateur ou d’un tiers. Nous pouvons enfin
        être légalement tenu de divulguer vos données à caractère personnel et
        ne pouvons dans ce cas nous y opposer.
      </p>

      <p>&nbsp;</p>

      <h3 class="text__subtitle">5. Hébergement des données</h3>

      <p>
        Le Site effy.capital.fr est hébergé par : Google Cloud Platform - Google
        France 8, rue de Londres 75009 Paris, France
      </p>

      <p>&nbsp;</p>

      <h3 class="text__subtitle">
        6. Durée de conservation des données à caractère personnel
      </h3>

      <p>
        Nous conservons vos données dans notre base uniquement le temps
        nécessaire à la réalisation des finalités visées ci-dessus et
        conformément à la réglementation en vigueur et notamment la
        réglementation spécifique en matière de CEE. Vos données sont ensuite
        archivées avec un accès restreint pour une durée supplémentaire pour des
        raisons limitées et autorisées par la loi (paiement, litiges, Pôle
        national des CEE…). Passé ce délai, elles sont supprimées.
      </p>

      <p>&nbsp;</p>

      <h3 class="text__subtitle">
        7. Quels sont vos droits sur vos données personnelles
      </h3>

      <p>
        Vous pouvez tout d’abord accéder et gérer vos informations personnelles
        en vous connectant, à l’aide de vos identifiants et mot de passe, sur
        votre compte utilisateur.
      </p>

      <p>
        Conformément à la réglementation applicable en matière de protection des
        données à caractère personnel, vous pouvez par ailleurs, à tout moment,
        et pour de justes motifs, exercer les droits suivants&nbsp;:
      </p>

      <ul>
        <li>
          <strong
            >Le droit d'accès, de rectification et droit à l'effacement
            :</strong
          >
          vous pouvez prendre connaissance, mettre à jour, modifier ou demander
          la suppression des données, dans la limite de l’article 17, 3 du RGPD,
          à savoir notamment dans le cas où la conservation de vos données
          découle d’une de nos obligations légales de conservation.<br />
          &nbsp;
        </li>
        <li>
          <strong>Le droit à la limitation du traitement :</strong> vous pouvez
          obtenir que nous limitation l’utilisation de vos données à caractère
          personnel dès lors que vous vous y êtes opposé, lorsque vous contestez
          l’exactitude de vos données, lorsque leur traitement est illicite, ou
          lorsque vous en avez besoin pour la constatation, l’exercice ou la
          défense de vos droits en justice. Dans le cas où vous exerceriez ce
          droit, nous devrons nous limiter au stockage des données. Aucune autre
          opération ne pourra, avoir lieu sur ces données personnelles.<br />
          &nbsp;
        </li>
        <li>
          <strong>Le droit à la portabilité des données :</strong> vous avez le
          droit de récupérer les données que vous nous avez fournies. En effet,
          lorsque le traitement est fondé sur le consentement ou sur un contrat,
          et effectué à l’aide de procédés automatisés, vous devez avoir le
          droit de recevoir les données dans un format structuré, couramment
          utilisé, lisible par machine et interopérable, et de les transmettre à
          un autre responsable du traitement sans que le responsable du
          traitement initial y fasse obstacle.<br />
          &nbsp;
        </li>
        <li>
          <strong
            >Le droit de ne pas faire l’objet d’une prise de décision
            automatisée :</strong
          >
          vous avez le droit de ne pas faire l’objet d’une décision fondée
          exclusivement sur un traitement automatisé, produisant des effets
          juridiques la concernant ou l’affectant, sauf lorsque cette décision
          est nécessaire à la conclusion ou à l’exécution d’un contrat, est
          autorisée légalement, ou est fondée sur son consentement. Le profilage
          y est expressément inclus.<br />
          &nbsp;
        </li>
        <li>
          <strong
            >Le droit de déterminer le sort des données à caractère personnel
            après la mort :</strong
          >
          vous pouvez organiser le sort de vos données à caractère personnel
          collectées et traitées après votre mort.<br />
          &nbsp;
        </li>
        <li>
          <strong
            >Le droit à la communication d’une violation de données à caractère
            personnel :</strong
          >
          dans certaines conditions, lorsqu’une violation de données à caractère
          personnel est susceptible d’engendrer un risque élevé pour les droits
          et libertés d’une personne physique, nous devons vous communiquer
          termes clairs et simples la nature de ladite violation, dans les
          meilleurs délais.
        </li>
      </ul>
      <h3 class="text__subtitle">
        <span style="font-size: 18px">7.2. Comment exercer ses droits</span>
      </h3>

      <p>
        Pour toute demande d’accès, d’opposition, rectification, portabilité,
        limitation, sort de vos données en cas de décès vous pouvez soit :
      </p>

      <ul>
        <li>adresser un email à l’adresse suivante : DPO@effy.fr</li>
        <li>
          adresser un courrier à : EFFY CONNECT, Responsable du Traitement, 33,
          avenue du Maine, 75015 Paris.
        </li>
      </ul>
      <p>Dans les deux cas vous devez&nbsp;:&nbsp;</p>

      <ul>
        <li>préciser l’objet de la demande ;</li>
        <li>indiquer votre nom, prénom ;</li>
        <li>
          joindre la photocopie recto-verso de votre carte d’identité ou
          passeport ;
        </li>
        <li>l’adresse email si vous nous avez contacté par courrier.</li>
      </ul>
      <p>
        EFFY CONNECT, Délégué à la Protection des Données, 33, avenue du Maine
        75755 Paris Cedex 15 – BP195.
      </p>

      <p>
        Nos services répondent aux demandes dans les meilleurs délais et au plus
        tard dans un délai d’un mois à compter de la réception de la demande.
      </p>

      <p>
        Enfin, vous pouvez également introduire une réclamation auprès de la
        CNIL (Commission Nationale de l’Informatique et des Libertés), 3 Place
        de Fontenoy – TSA 80715 – 75334 Paris Cedex 07 ou sur son site
        internet&nbsp;:
        <a href="https://www.cnil.fr/" target="_blank">www.cnil.fr</a>.
      </p>

      <p>&nbsp;</p>

      <h3 class="text__subtitle">8. Sécurité</h3>

      <p>
        Effy Connect est attentif au caractère privé et à l’importance de vos
        données et engage tous les efforts possibles pour les conserver en
        sécurité et vous garantir votre tranquillité.
      </p>

      <p>
        Le chiffrement est la clé. Nous chiffrons vos données quand elles sont
        transférées pour garantir qu'elles ne sont pas lues par des tiers. Nous
        utilisons des technologies comme HTTPS et Transport Layer Security.
      </p>

      <p>&nbsp;</p>

      <h3 class="text__subtitle">
        9. Autres services disponibles à partir du site
      </h3>

      <p>
        Le Site peut également comporter des liens vers des réseaux sociaux
        (tels que Facebook ou Twitter). Toutefois nous ne contrôlons pas les
        cookies utilisés par ces sites Web externes. Pour plus d’informations
        sur les cookies des réseaux sociaux ou autres sites Web externes, nous
        recommandons de vérifier leurs propres politiques de cookies.
      </p>

      <p>&nbsp;</p>

      <h3 class="text__subtitle">
        10. Mise à jour de la politique de données personnelles
      </h3>

      <p>
        Nos offres et nos services étant en constante évolution, nous nous
        réservons le droit de modifier la présente politique de protection des
        données. Dans ce cas, cela vous sera notifié de manière visible sur le
        Site. Ainsi nous vous conseillons de consulter régulièrement cette page
        pour prendre connaissance des éventuelles modifications ou mises à jour
        que nous pourrions y avoir apporté.
      </p>

      <h2>Nos cookies</h2>

      <h3 class="text__subtitle">1. Qu'est-ce qu'un cookie ?</h3>

      <p>
        Un cookie est un fichier texte de taille réduite que le serveur (i.e le
        site) que vous visitez enregistre sur votre disque dur ou dans la
        mémoire de votre navigateur et qui permet à votre ordinateur de stocker
        différentes données techniques permettant le contrôle général de l’accès
        du public ou à la personnalisation des pages affichées en vue de vos
        prochaines interactions avec ce site.&nbsp;
      </p>

      <p>
        A ce titre, un bandeau d’information s’affiche lors de votre connexion
        sur le Site afin de vous informer préalablement du dépôt de cookies, de
        leur présence afin de vous permettre de vous opposer à leur dépôt sur
        votre terminal.
      </p>

      <p>&nbsp;</p>

      <h3 class="text__subtitle">
        2. Quels cookies utilise-t-on sur le site ?
      </h3>

      <p>Au sein de notre Site, nous utilisons des cookies permettant&nbsp;:</p>

      <ul>
        <li>
          De reconnaitre votre navigation comme ancien visiteur enregistré afin
          d’enregistrer toutes les préférences envoyées lors de l’utilisation du
          Site par votre navigateur ;
        </li>
        <li>
          D’améliorer votre navigation sur le site afin de vous permettre
          d’utiliser ses différentes fonctionnalités, telle que vous permettre
          de vous identifier et de vous connecter à votre compte utilisateur ;
        </li>
        <li>
          D’établir des statistiques et volumes de fréquentation et
          d’utilisation des divers éléments du Site (rubriques et contenus
          visités, date et heure de consultation…), permettant d’améliorer le
          Site et de personnaliser votre navigation ;
        </li>
        <li>
          A des fins commerciales et publicitaires, pour l’affichage de
          bannières publicitaires en lien avec notre activité exclusivement ;
        </li>
        <li>De mettre en œuvre des mesures de sécurité.</li>
      </ul>
      <p>
        Le Site peut également comporter des liens de réseaux sociaux (tels que
        Facebook ou Twitter). Toutefois, nous ne contrôlons pas les cookies
        utilisés par ces sites Web externes. Pour plus d’informations sur les
        cookies des réseaux sociaux ou autres sites Web externes, nous vous
        recommandons de vérifier leurs propres politiques de cookies.
      </p>

      <p>&nbsp;</p>

      <h3 class="text__subtitle">
        3. Gestion des cookies dans votre navigateur
      </h3>

      <p>
        Ce dépôt de cookies réalisé dans le cadre de l’utilisation du Site ne
        permet de vous identifier personnellement mais il enregistre des
        informations relatives à la navigation de votre terminal sur le Site que
        nous pourrons lire lors de vos visites ultérieures.&nbsp;<br />
        Les informations collectées sont pour notre usage exclusif et celui de
        nos partenaires, et ne sont en aucun cas cédés à des tiers.
      </p>

      <p><b>Comment désactiver les cookies&nbsp;?</b></p>

      <p>
        Vous pouvez gérer et supprimer les cookies et configurer en mode
        «&nbsp;Navigation privée&nbsp;» en fonction du navigateur que vous
        utilisez, mais vous pourrez être amené à perdre certaines données de
        connexion.
      </p>

      <p><b>Cookies sur Internet explorer&nbsp;:</b></p>

      <ul>
        <li>Choisissez « Outils » puis « Options Internet » ;</li>
        <li>Cliquez sur l’onglet « Confidentialité » ;</li>
        <li>
          Faites glisser le curseur jusqu’à choisir le paramétrage désiré ;
        </li>
        <li>
          Pour un paramétrage plus fin des cookies, cliquez sur « Avancé »
        </li>
        <li>
          Cochez la case « Ignorer la gestion automatique des cookies » pour
          gérer manuellement l’ajout de cookies : Sous « Cookies internes »,
          sélectionnez l’option « Accepter » ou « Demander », Sous « Cookies
          tierce partie », sélectionnez l’option « Accepter » ou « Demander »
          (si vous sélectionnez l’option « Demander », votre accord est exigé
          chaque fois qu’un site Web tente de vous envoyer un cookie) ;
        </li>
        <li>Cliquez sur OK pour fermer la fenêtre « Options Internet ».</li>
      </ul>
      <p><b>Cookies sur Mozilla Firefox :</b></p>

      <ul>
        <li>Choisissez « Outils » puis « Options » ;</li>
        <li>Sélectionnez le panneau « Vie privée »</li>
        <li>
          Paramétrez « Règles de conservation » : « utiliser les paramètres
          personnalisés pour l’historique »
        </li>
        <li>
          Cochez la case « Accepter les cookies » pour activer les cookies et
          décochez-la pour les désactiver ;
        </li>
        <li>
          Choisissez la durée de conservation des cookies :
          <ul>
            <li>
              Les conserver jusqu’à « leur expiration » : Chaque cookie sera
              supprimé quand il aura atteint sa date d’expiration qui est
              déterminée par le site qui envoie le cookie ;
            </li>
            <li>
              Les conserver jusqu’à : « la fermeture de Firefox » : Les cookies
              qui sont stockés sur votre ordinateur seront supprimés à la
              fermeture de Firefox ;
            </li>
            <li>
              Les conserver jusqu’à : « me demander à chaque fois » : Affiche
              une alerte chaque fois qu’un site Web essaie d’envoyer un cookie
              pour demander votre approbation.
            </li>
          </ul>
        </li>
        <li>Cliquez sur OK pour fermer la fenêtre « Option »</li>
      </ul>
      <p><b>Cookies sur Safari&nbsp;:</b></p>

      <ul>
        <li>Choisissez «&nbsp;Préférences&nbsp;» dans le menu Safari&nbsp;;</li>
        <li>Cliquez sur l’icône «&nbsp;Security&nbsp;» (Sécurité)&nbsp;;</li>
        <li>Les paramètres de cookie s’affichent&nbsp;;</li>
        <li>Choisissez les paramètres voulus.</li>
      </ul>
      <p><b>Cookies sur Google Chrome :</b></p>

      <ul>
        <li>
          Cliquez sur l’icône représentant une clé à molette qui est située dans
          la barre d’outils du navigateur ;
        </li>
        <li>
          Sélectionnez « Option » (Préférences sous Mac et Linux, Paramètres sur
          un Chromebook) ;
        </li>
        <li>Cliquez sur l’onglet « Options avancées » ;</li>
        <li>
          Cliquez sur l’option « Paramètre de contenu » de la section «
          Confidentialité »
        </li>
        <li>
          Dans la boîte de dialogue « Paramètres de contenu » qui s’affiche,
          cliquez sur l’onglet « Cookies » ;
        </li>
        <li>
          Cliquez sur « Cookies et données de site » pour ouvrir la boîte de
          dialogue « Cookies et autres données »
        </li>
      </ul>
      <p><b>Supprimer&nbsp;les cookies&nbsp;:</b></p>

      <ul>
        <li>
          Supprimer tous les cookies, cliquez sur « Tout supprimer » en bas de
          la boîte de dialogue ;
        </li>
        <li>
          Supprimer un cookie spécifique, sélectionnez le site ayant créé le
          cookie, puis le cookie.
        </li>
        <li>Cliquez ensuite sur « Supprimer ».</li>
      </ul>
      <p><b>Bloquer les cookies par défaut :</b></p>

      <ul>
        <li>
          Bloquer tous les cookies&nbsp;: sélectionnez l’option «&nbsp;Interdire
          à tous les sites de stocker des données&nbsp;».<br />
          /!\&nbsp; La plupart des sites qui nécessitent que vous vous
          identifiez ne fonctionneront pas correctement tant que cette option
          sera sélectionnée. L’icône cookie bloqué apparait dans la barre
          d’adresse chaque fois qu’un cookie est bloqué.
        </li>
        <li>
          <p>
            Bloquer uniquement les cookies tiers&nbsp;: cochez la case
            «&nbsp;Ignorez les exceptions et bloquer l’enregistrement des
            cookies tiers&nbsp;». Lorsque cette option est sélectionnée, aucun
            cookie tiers n’est accepté, y compris ceux des sites que vous avez
            ajoutés aux exceptions et dont vous avez autorisé les cookies.
          </p>
        </li>
      </ul>
      <p><b>Autoriser les cookies par défaut&nbsp;</b></p>

      <p>
        Vérifiez que l’option «&nbsp;Autoriser le stockage des données
        locales&nbsp;» est cochée, afin d’autoriser à la fois les cookies
        propriétaires et tiers. Si vous ne souhaitez accepter que les cookies
        propriétaires, cochez la case «&nbsp;Bloquer tous les cookies tiers sans
        exception&nbsp;».
      </p>

      <p><b>Opéra :</b></p>

      <ul>
        <li>
          Cliquez sur le menu « Fichier » (File menu) sur la barre d’outils
          Opera (Opera tool bar) ;
        </li>
        <li>Allez sur « Préférences » au bas du menu « Fichier » ;</li>
        <li>
          Cliquez sur « Privacy » et sélectionnez une des options disponibles.
        </li>
      </ul>
    </div>
  </div>
</section>
