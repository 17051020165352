<section id="section-1">
  <div class="container">
    <div class="wrapper wrapper-half-width">
      <img
        class="logo"
        src="https://www.effy.fr/common-assets/images/logo_effy.svg"
      />
      <h1>
        Vous envisagez de réaliser des travaux de rénovation énergétique ?
      </h1>
      <hr class="separator" />
      <p>
        <b
          >Capital s’associe au spécialiste Effy pour vous aider à mener à bien
          vos travaux d’isolation, de changement de chauffage…</b
        ><br />
        Du choix de l’artisan à l’obtention de vos aides (Primes CEE,
        MaPrimeRénov’…), nous vous accompagnons dans l’ensemble de vos
        démarches. 
      </p>
    </div>
  </div>
</section>

<section id="section-2">
  <img
    src="../assets/images/illu-hero.svg"
    alt=""
    class="offset-illustration"
  />
  <div class="container">
    <div class="wrapper wrapper-third-width">
      <h3>Choisissez le parcours que vous souhaitez,</h3>
      <p>
        les spécialistes de la rénovation énergétique d’Effy vous conseilleront
        les solutions les plus adaptées à vos besoins.
      </p>
    </div>

    <div class="card-group">
      <div class="card-item" *ngFor="let card of cards">
        <div class="card-header">
          <div
            class="img-container"
            [style.background-image]="
              'url(.././assets/images/' + card.imgUrl + '.jpg)'
            "
          ></div>
        </div>
        <div class="card-body">
          <p>
            {{ card.description }}
          </p>
          <a class="card-link" href="{{ card.linkUrl }}">{{ card.title }}</a>
        </div>
      </div>
    </div>
  </div>
</section>
