import { Component } from '@angular/core';

@Component({
  selector: 'partner-capital-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})

export class OnBoardingComponent {
  effyCapitalParcoursRelativeBaseUrl = `/parcours/1/`;

  cards =
    [
      {
        "title": "Découvrir mes aides",
        "description": `MaPrimeRénov', Prime CEE... Faites des économies dans vos travaux`,
        "imgUrl": "mon-artisan",
        "linkUrl": `${this.effyCapitalParcoursRelativeBaseUrl}travaux-aides`
      },
      {
        "title": "Isoler ma maison",
        "description": `Trouvez un artisan de qualité et obtenez vos aides pour votre projet d’isolation`,
        "imgUrl": "ma-maison",
        "linkUrl": `${this.effyCapitalParcoursRelativeBaseUrl}isolation`
      },
      {
        "title": "Changer mon chauffage",
        "description": `Trouvez un artisan de qualité et obtenez vos aides pour votre projet de chauffage`,
        "imgUrl": "mon-chauffage",
        "linkUrl": `${this.effyCapitalParcoursRelativeBaseUrl}pompe-a-chaleur`
      },
    ]
}
